import { gql } from '@apollo/client'

import { FrgUserForLink } from './user'

export const FrgVehicleForLink = gql`
  fragment FrgVehicleForLink on VehicleType {
    id
    name
    logo
    color
  }
`

export const GET_ALL_VEHICLES = gql`
  ${FrgVehicleForLink}
  ${FrgUserForLink}
  query GetAllVehicles(
    $pageSize: Int
    $offset: Int
    $name: String
    $status: String
    $order: String
    $orderBy: String
    $activeProjects: String
    $Type: String
    $search: String
    $drivers: [ID]
    $vehicleTypes: [ID]
    $primaryDriver: [ID]
  ) {
    allVehicles(
      first: $pageSize
      offset: $offset
      name: $name
      status_Iexact: $status
      order: $order
      orderBy: $orderBy
      activeProjects: $activeProjects
      Type: $Type
      search: $search
      drivers: $drivers
      vehicleTypes: $vehicleTypes
      primaryDriver: $primaryDriver
    ) {
      totalCount
      edges {
        node {
          ...FrgVehicleForLink
          status
          licensePlateNumber
          vehicleType {
            id
            name
            Type
            body
            bodyMechanization
            liftingMechanization
            loadingLocation
          }
          realtimeLocation {
            id
            latitude
            longitude
            name
          }
          primaryDriver {
            id
            user {
              ...FrgUserForLink
            }
          }
          drivers {
            edges {
              node {
                id
                user {
                  ...FrgUserForLink
                }
              }
            }
          }
          project {
            id
            name
          }
          rfidVehicles {
            provider
            enabled
          }
        }
      }
    }
  }
`
